<template functional>
  <div class="login-page-layout">
    <div class="login-page-layout__wrapper px-4 px-lg-0">
      <slot />
      <slot name="before-wrapper" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppLoginPageLayout',
};
</script>
<style scoped lang="scss">
.login-page-layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  height: 100%;
  justify-content: center;
  padding-block: 40px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: grid;
    gap: 0;
    // padding: 0;
    place-items: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    padding: 0 16px;
  }
}
</style>
